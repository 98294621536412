import React from 'react'

const FreeIcon = ({ style, className, ...restProps }) => {
  return (
    <svg
      xmlns="http: //www.w3.org/2000/svg"
      className={className}
      style={{ display: 'block', ...style }}
      viewBox="0 0 200 200"
      fill="none"
      {...restProps}
    >
      <path
        d="M106.047 92.8105H105.721V74.8632H106.047C109.474 74.8632 112.737 76.9842 114.205 80.0842C115.674 83.3474 119.589 84.8158 123.016 83.3474C126.279 81.8789 127.747 77.9632 126.279 74.5368C122.689 66.7053 114.695 61.6474 106.211 61.6474H105.884V51.8579C105.884 48.9211 103.6 46.6368 100.663 46.6368C97.7263 46.6368 95.4421 48.9211 95.4421 51.8579V61.6474H95.1158C89.2421 61.6474 83.6947 63.9316 79.4526 68.1737C75.2105 72.4158 72.9263 77.9632 72.9263 83.8368C72.7632 96.0737 82.7158 106.026 94.9526 106.026H95.2789V123.974H94.9526C91.3632 123.974 88.2632 122.016 86.7947 118.753C85.3263 115.489 81.2474 114.021 77.9842 115.489C74.7211 116.958 73.2526 121.037 74.7211 124.3C78.3105 132.132 86.3053 137.189 94.9526 137.189H95.2789V146.979C95.2789 149.916 97.5632 152.2 100.5 152.2C103.437 152.2 105.721 149.916 105.721 146.979V137.189H106.047C118.284 137.189 128.237 127.237 128.237 115C128.237 109.126 125.953 103.579 121.874 99.3368C117.632 95.2579 111.921 92.8105 106.047 92.8105ZM94.9526 92.8105C90.0579 92.8105 85.9789 88.7316 85.9789 83.8368C85.9789 81.3895 86.9579 79.1053 88.5895 77.4737C90.3842 75.6789 92.5053 74.8632 94.9526 74.8632H95.2789V92.8105H94.9526ZM106.047 124.137H105.721V106.026H106.047C108.495 106.026 110.779 107.005 112.411 108.637C114.042 110.268 115.021 112.553 115.021 115C115.021 120.058 110.942 124.137 106.047 124.137Z"
        fill="currentColor"
      />
      <path
        d="M100.5 22C57.7526 22 23 56.7526 23 99.5C23 142.247 57.7526 177 100.5 177C143.247 177 178 142.247 178 99.5C178 56.7526 143.247 22 100.5 22ZM36.2158 99.5C36.2158 84.1632 41.6 70.1316 50.5737 59.0368L59.5474 68.0105C60.8526 69.3158 62.4842 69.9684 64.2789 69.9684C66.0737 69.9684 67.7053 69.3158 69.0105 68.0105C71.6211 65.4 71.6211 61.1579 69.0105 58.7105L60.0368 49.7368C71.1316 40.7632 85.1632 35.3789 100.5 35.3789C135.905 35.3789 164.784 64.2579 164.784 99.6632C164.784 115 159.4 129.032 150.426 140.126L141.289 130.989C138.679 128.379 134.6 128.379 131.989 130.989C129.379 133.6 129.379 137.842 131.989 140.289L140.963 149.263C129.868 158.4 115.837 163.784 100.5 163.784C65.0947 163.784 36.2158 134.905 36.2158 99.5Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default FreeIcon
