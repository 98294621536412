import styles from "./InfoSsw.module.scss"
import React from "react"
import cx from "clsx"
import Text from "../Text/Text"
import Link from "../Link/Link"
import Container from "../../layouts/Container/Container"

const InfoCard = ({ children }) => {
  return <section className={styles.card}>{children}</section>
}

const InfoSsw = ({ children, className, ...restProps }) => {
  return (
    <Container narrow as="section">
      <div className={cx(styles.root)} {...restProps}>
        <InfoCard>
          <img
            className={styles.tower}
            // src="https://talentindonesia.s3.ap-southeast-1.amazonaws.com/assets/stock/photo-tower.jpg"
            src="https://talentindonesia.s3.ap-southeast-1.amazonaws.com/assets/stock/photo-tower.png"
          />
          <div className={styles.content}>
            <Text heading3Alt as="h2" className={styles.text}>
              Apa itu visa SSW (Tokutei Ginou)?
            </Text>
            <Link className={styles.link} to="/tentang-visa-ssw">
              Selengkapnya
            </Link>
          </div>
        </InfoCard>
        <InfoCard>
          <img
            className={styles.calendar}
            // src="https://talentindonesia.s3.ap-southeast-1.amazonaws.com/assets/stock/photo-calendar.png"
            src="https://talentindonesia.s3.ap-southeast-1.amazonaws.com/assets/stock/photo-calendar-side.png"
          />
          <div className={styles.content}>
            <Text heading3Alt as="h2" className={styles.text}>
              Siap mengikuti ujian visa SSW?
            </Text>
            <Link className={styles.link} to="/info-ujian-ssw">
              Lihat Jadwal Ujian
            </Link>
          </div>
        </InfoCard>
        <InfoCard>
          <img
            className={styles.trainer}
            // src="https://talentindonesia.s3.ap-southeast-1.amazonaws.com/assets/photo-trainer-removebg.png"
            src="https://talentindonesia.s3.ap-southeast-1.amazonaws.com/assets/stock/photo-trainer.png"
          />
          <div className={styles.content}>
            <div>
              <Text heading3Alt as="h2" className={styles.text}>
                Belum punya sertifikat untuk SSW?
              </Text>
              <Text small as="p" secondary className={styles.subtitle}>
                Ada program pelatihan kerja untuk mempersiapkanmu!*
              </Text>
            </div>
            <Link className={styles.link} to="/pelatihan-kerja">
              Selengkapnya
            </Link>
          </div>
        </InfoCard>
      </div>
      <div className={styles.partner}>
        <Text as="p" caption secondary>
          *Bekerja sama dengan
        </Text>
        <Link external to="http://os-selnajaya.com">
          <img
            className={styles.logo}
            src="https://talentindonesia.s3.ap-southeast-1.amazonaws.com/assets/jp/logo/os.png"
            width={128}
            height={32}
          />
        </Link>
      </div>
    </Container>
  )
}

export default InfoSsw
