import styles from './LandingRegistration.module.scss'
import React from 'react'
import cx from 'clsx'
import { useRouter } from 'next/router'

import Container from '../../layouts/Container/Container'

import Text from '../Text/Text'
import Button from '../Button/Button'

const LandingRegistration = ({ title, cta, className, ...restProps }) => {
  const router = useRouter()

  return (
    <section className={cx(styles.root)} {...restProps}>
      <Container>
        {/* <Text heading2 as="h2" className={styles.title} align="center">
          Showcase your abilities <br /> and let employers find you!
        </Text> */}
        <h2
          className={styles.title}
          dangerouslySetInnerHTML={{
            __html: title
              ? title
              : `Daftar &amp; tunjukkan kemampuanmu sekarang!
                <br />
                Dan dapatkan berbagai tawaran kerja menarik dari para recruiter`,
          }}
        />
        {/* <Text large as="p" secondary align="center">
          Buat resume kamu &amp; dapatkan berbagai tawaran pekerjaan menarik
          dari para recruiter!
        </Text> */}
        <footer className={styles.footer}>
          <Button primary xlarge onClick={() => router.push('/register')}>
            {cta ?? 'Daftar & Buat Resume Sekarang'}
          </Button>
        </footer>
      </Container>
    </section>
  )
}

export default LandingRegistration
