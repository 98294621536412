import React from "react"
import { getContentForHomepage } from "../utils/api"

import LandingSsw from "../components/LandingSsw/LandingSsw"
import LandingScheduleSsw from "../components/LandingScheduleSsw/LandingScheduleSsw"
import LandingRegistration from "../components/LandingRegistration/LandingRegistration"
import LandingWhy from "../components/LandingWhy/LandingWhy"
import LandingHero from "../components/LandingHero/LandingHero"
import LandingPelatihan from "../components/LandingPelatihan/LandingPelatihan"
import DefaultMarketing from "../layouts/DefaultMarketing/DefaultMarketing"
import MobileMenu from "../components/MobileMenu/MobileMenu"
import InfoSsw from "../components/InfoSsw/InfoSsw"

const Homepage = ({ data }) => {
  return (
    <DefaultMarketing withFooter withFab withMobileMenu withBannerInfo>
      <LandingHero
        title={data?.title_hero}
        subtitle={data?.subtitle_hero}
        cover={data?.cover_hero}
      />
      <LandingWhy
        title={data?.title_why ? data?.title_why : null}
        benefits={data?.benefits}
      />
      <LandingRegistration title={data?.subtitle_why} cta={data?.cta_why} />
      <InfoSsw />
      {/* <LandingSsw
        title={data?.title_ssw}
        body={data?.body_ssw}
        cta={data?.cta_ssw}
      /> */}
      {/* <LandingScheduleSsw /> */}
      {/* <LandingPelatihan
        title={data?.title_pelatihan}
        body={data?.body_pelatihan}
        cta={data?.cta_pelatihan}
      /> */}
    </DefaultMarketing>
  )
}

export async function getStaticProps() {
  const data = (await getContentForHomepage()) ?? {}

  return {
    props: {
      data,
    },
  }
}

export default Homepage
