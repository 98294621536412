import styles from './LandingWhy.module.scss'
import React, { useEffect, useState } from 'react'
import cx from 'clsx'
import Portal from '@reach/portal'
import { DialogOverlay, DialogContent } from '@reach/dialog'

import BriefcaseFilledIcon from '../icons/BriefcaseFilledIcon'
import FreeIcon from '../icons/FreeIcon'
import CompleteProcessIcon from '../icons/CompleteProcessIcon'

import Container from '../../layouts/Container/Container'

import { useLockBodyScroll } from '../../hooks'

import Text from '../Text/Text'
import Button from '../Button/Button'
import FlowIllustration from '../icons/FlowIllustration'
import Link from '../Link/Link'
import { useRouter } from 'next/router'

const DialogBenefit = ({ dialog, isOpen, handleClose }) => {
  useLockBodyScroll()

  return (
    <DialogOverlay
      onDismiss={handleClose}
      isOpen={isOpen}
      className={styles.dialogArea}
    >
      <DialogContent
        aria-label="dialog for benefit"
        className={styles.dialogContent}
      >
        <main className={styles.dialogMainArea}>{dialog}</main>
        <footer className={styles.dialogFooter}>
          <Button primaryText alt onClick={handleClose}>
            Tutup
          </Button>
        </footer>
      </DialogContent>
    </DialogOverlay>
  )
}

const Benefit = ({ title, trigger, subtitle, icon, dialog, label }) => {
  const router = useRouter()
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (router.query.show === `benefit-${label}`) {
      setShow(true)
    } else {
      setShow(false)
    }
  }, [router, label])

  return (
    <li className={styles.benefitWrapper}>
      <figure className={styles.iconWrapper}>{icon}</figure>
      <Text heading4 as="h3" className={styles.benefitTitle}>
        {title}
      </Text>
      <Text medium as="p" className={styles.benefitDetails} secondary>
        {subtitle}
      </Text>
      {trigger && (
        <button
          className={styles.benefitTrigger}
          onClick={() =>
            router.push(`/?show=benefit-${label}`, undefined, { scroll: false })
          }
        >
          {trigger}
        </button>
      )}
      {show && (
        <DialogBenefit
          dialog={dialog}
          handleClose={() => router.push(`/`, undefined, { scroll: false })}
          isOpen={router?.query?.show === `benefit-${label}`}
        />
      )}
    </li>
  )
}

const LandingWhy = ({ title, benefits, className, ...restProps }) => {
  return (
    <section className={cx(styles.root)} {...restProps}>
      <Container>
        <header className={styles.header}>
          <h2
            className={styles.title}
            dangerouslySetInnerHTML={{
              __html: title
                ? title
                : `<span>
                  Mengapa harus membuat akun <br /> Talent Indonesia?
                </span>`,
            }}
          />
        </header>
        <ul className={styles.gridWrapper}>
          <Benefit
            label="recruitment"
            icon={<BriefcaseFilledIcon className={styles.icon} />}
            title={
              benefits?.length > 0 && benefits[0]?.title
                ? benefits[0]?.title
                : 'Diseleksi Langsung'
            }
            subtitle={
              benefits?.length > 0 && benefits[0]?.body
                ? benefits[0]?.body
                : 'Tanpa perantara, langsung diseleksi Perusahaan Jepang'
            }
            trigger="Selengkapnya"
            dialog={
              <>
                <FlowIllustration className={styles.illustration} />
                <Text small as="p" align="center" className={styles.paragraph}>
                  Proses seleksi dilakukan langsung oleh perusahaan Jepang.{' '}
                  <br />
                  Admin Talent Indonesia hanya bertugas membantu agar proses
                  perekrutan berjalan dengan lancar.
                </Text>
              </>
            }
          />
          <Benefit
            label="free"
            icon={<FreeIcon className={styles.icon} />}
            title={
              benefits?.length > 0 && benefits[1]?.title
                ? benefits[1]?.title
                : 'Tanpa Biaya *'
            }
            subtitle={
              benefits?.length > 0 && benefits[1]?.body
                ? benefits[1]?.body
                : 'Dari pendaftaran sampai diterima perusahaan, gratis!'
            }
            trigger="*Selengkapnya"
            dialog={
              <ul className={styles.syarat}>
                <Text medium as="li" className={styles.paragraph}>
                  Jika persyaratan sudah lengkap{' '}
                  <Link to="/tentang-visa-ssw">Lihat info persyaratan</Link>
                </Text>
                <Text medium as="li" className={styles.paragraph}>
                  Biaya yang dibutuhkan untuk mendapatkan sertifikat seperti
                  biaya kursus, biaya ujian, transportasi, dan akomodasi
                  ditanggung sendiri oleh kandidat.
                </Text>
                <Text medium as="li" className={styles.paragraph}>
                  Setelah kandidat dinyatakan lulus seleksi, ada biaya
                  pengurusan Visa yang ditanggung sendiri oleh kandidat.
                </Text>
              </ul>
            }
          />
          <Benefit
            icon={<CompleteProcessIcon className={styles.icon} />}
            title={
              benefits?.length > 0 && benefits[2]?.title
                ? benefits[2]?.title
                : 'Pendaftaran hingga Keberangkatan'
            }
            subtitle={
              benefits?.length > 0 && benefits[2]?.body
                ? benefits[2]?.body
                : 'Tim Talent Indonesia akan membantu keperluanmu hingga berangkat ke Jepang'
            }
          />
        </ul>
      </Container>
    </section>
  )
}

export default LandingWhy
