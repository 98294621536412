import React from 'react'

const BriefcaseFilledIcon = ({ style, className, ...restProps }) => {
  return (
    <svg
      xmlns="http: //www.w3.org/2000/svg"
      className={className}
      style={{ display: 'block', ...style }}
      viewBox="0 0 200 200"
      fill="none"
      {...restProps}
    >
      <path
        d="M117.869 111.658V113.013C117.869 123.181 109.573 131.484 99.4153 131.484C89.2576 131.484 80.9621 123.181 80.9621 113.013V111.658L22.3859 100.304C21.2008 100.135 20.0158 99.796 19 99.1182V150.293C19 162.833 29.1577 173 41.6856 173H157.314C169.842 173 180 162.833 180 150.293V99.1182C178.984 99.6265 177.799 99.9654 176.614 100.304L117.869 111.658Z"
        fill="currentColor"
      />
      <path
        d="M157.484 53.1962H136.83V44.7235C136.83 31.6756 126.164 21 113.129 21H85.7028C72.6671 21 62.0015 31.6756 62.0015 44.7235V53.1962H41.3474C28.9888 53.1962 19.0004 63.194 19.0004 75.5641V85.0535C19.0004 88.1037 21.2012 90.6455 24.0793 91.3233L80.9626 102.338C81.1318 92.34 89.258 84.3757 99.4158 84.3757C109.404 84.3757 117.7 92.34 117.869 102.338L174.583 91.3233C177.63 90.6455 179.831 88.1037 179.831 85.0535V75.3947C179.831 63.194 169.843 53.1962 157.484 53.1962ZM79.4389 44.5541C79.4389 41.165 82.3169 38.4537 85.5335 38.4537H113.129C116.515 38.4537 119.223 41.3345 119.223 44.5541V53.0268H79.4389V44.5541Z"
        fill="currentColor"
      />
      <path
        d="M108.727 113.013V102.846C108.727 97.7625 104.494 93.5262 99.4154 93.5262C94.3365 93.5262 90.1041 97.7625 90.1041 102.846V113.013C90.1041 118.097 94.3365 122.333 99.4154 122.333C104.494 122.333 108.727 118.097 108.727 113.013Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default BriefcaseFilledIcon
