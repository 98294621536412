import React from 'react'

const SearchIcon = ({ style, className, ...restProps }) => {
  return (
    <svg
      xmlns="http: //www.w3.org/2000/svg"
      className={className}
      style={{
        display: 'block',
        fill: 'none',
        stroke: 'currentcolor',
        strokeWidth: '4px',
        overflow: 'visible',
        ...style,
      }}
      viewBox="0 0 32 32"
      aria-hidden="true"
      role="presentation"
      focusable="false"
      {...restProps}
    >
      <g fill="none">
        <path d="m13 24c6.0751322 0 11-4.9248678 11-11 0-6.07513225-4.9248678-11-11-11-6.07513225 0-11 4.92486775-11 11 0 6.0751322 4.92486775 11 11 11zm8-3 9 9"></path>
      </g>
    </svg>
  )
}

export default SearchIcon
